export const experienceData = [
    {
        id: 1,
        company: 'Globel IT App',
        jobtitle: 'Web Developer',
        startYear: '2018',
        endYear: '2019'
    },
    {
        id: 2,
        company: 'Webndevs',
        jobtitle: 'Backend Developer',
        startYear: '2019',
        endYear: '2020'
    },
    {
        id: 3,
        company: 'Webndevs',
        jobtitle: 'Marketing',
        startYear: '2020',
        endYear: 'Present'
    },
]